import {
  ADD_PHOTOPERMISSION,
  GET_ALLPHOTOPERMISSION,
  GET_PHOTOPERMISSION,
  LOADING_PHOTOPERMISSION,
  STOP_LOADING_PHOTOPERMISSION,

} from "../types";

const initialState = {
  all_photopermission: [],
  loading_state: false,
}


export default function (state = initialState, action) {
  switch (action.type) {

      case ADD_PHOTOPERMISSION:
          return {
              ...state,
              all_reconciliation: [...state.all_photopermission, action.payload],
              loading_state: false
          }

      case GET_ALLPHOTOPERMISSION:
          return {
              ...state,
              all_photopermission: action.payload.data,
              loading_state: false,
          }

      case GET_PHOTOPERMISSION:
          return {
              ...state,
              all_photopermission: action.payload.data,
              loading_state: false
          }
      case LOADING_PHOTOPERMISSION:
          return {
              ...state,
              loading_state: true
          }
      case STOP_LOADING_PHOTOPERMISSION:
          return {
              ...state,
              loading_state: false
          }
      default:
          return state
  }
}